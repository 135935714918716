<template>
  <Browse
    :columns="[
      'email',
      'name',
      'initials',
      'role',
      'email_verified_at',
      'remember_token',
      'phone',
    ]"
    :formats="{
      role: 'Select*',
    }"
    itemsPerPage="10"
    :searchOnStart="true"
    table="users"
  />
</template>

<script>
import Browse from "@/components/Browse.vue";

export default {
  components: {
    Browse,
  },
};
</script>
