var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Browse',{attrs:{"columns":[
    'email',
    'name',
    'initials',
    'role',
    'email_verified_at',
    'remember_token',
    'phone' ],"formats":{
    role: 'Select*',
  },"itemsPerPage":"10","searchOnStart":true,"table":"users"}})}
var staticRenderFns = []

export { render, staticRenderFns }